@import url('https://fonts.googleapis.com/css2?family=Chonburi&family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GoodTiming';
  font-weight: bold;
  src: url('assets/fonts/GoodTiming/good_timing_bd.otf') format('opentype');
  font-display: swap;
}

html {
  background-color: white;
  overflow-x: hidden ;
}

/* html::-webkit-scrollbar {
  display: none;
} */

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-img {
  background-image: url("./assets/header.png"),linear-gradient(180deg, rgba(21, 24, 41, 0) 19.57%, #2d0734 100%)  ;
}

.carousel .slide {
  z-index: 100 !important;
}
.swiper-button-next, .swiper-button-prev {
  color: #f7ce36 !important;
  margin: 0 8px !important;
}


@layer components {
  body {
    @apply text-base !important
  }

  .parent, .child {
    @apply opacity-0
  }

}
